<template>
  <p v-if="stock && stock.expiryDate" class="product-expiry-date" :data-test-id="elementTest + '-expiry-date'">
    <span
      v-if="!plainText"
      class="product-expiry-date-label"
      :class="classVariantLabel"
      :data-test-id="elementTest + '-expiry-date-label'"
    >
      {{ $t('product.expiryDate') }}
    </span>
    <span
      class="product-expiry-date-value"
      :data-test-id="elementTest + '-expiry-date-value'"
      :class="[stock.expiryDateStatus?.toLowerCase(), classVariantValue]"
    >
      {{ formattedDate() }}
    </span>
    <span
      v-if="plainText"
      class="product-expiry-date-label-plain"
      :class="classVariantLabel"
      :data-test-id="elementTest + '-expiry-date-label-plain'"
    >
      {{ $t('stock.expiryDateText') }}
    </span>
  </p>
</template>
<script>
export default {
  name: 'ExpiryDateLabel',
  props: {
    stock: { type: Object, default: () => null },
    plainText: { type: Boolean, default: false },
    classVariantLabel: { type: String, default: null },
    classVariantValue: { type: String, default: null },
    elementTest: { type: String, default: '' },
  },
  methods: {
    formattedDate() {
      const date = new Date(this.stock.expiryDate)
      if (date) {
        const parsedDate = date.toLocaleDateString()
        if (parsedDate) {
          // replace all crash sometimes
          return parsedDate.replace('/', '.').replace('/', '.')
        }
      }
      return null
    },
  },
}
</script>

<style lang="scss">
.product-expiry-date {
  display: flex;
  gap: $spacing-xs;
  align-items: end;
  font-weight: 800;
  font-size: pxToRem(12px);
  line-height: inherit !important;

  @include mq($mq-lg) {
    font-size: pxToRem(10px);
  }
  @include mq($mq-md) {
    font-size: pxToRem(7px);
  }

  &-label {
    color: var(--dark-grey);
    opacity: 0.6;
    text-transform: uppercase;

    &-plain {
      text-transform: none !important;
    }

    &.slug-label {
      font-size: 0.85rem;
      margin-left: 6px;
    }

    &.sm {
      font-size: pxToRem(12px);
      @include mq($mq-lg) {
        font-size: pxToRem(10px);
      }
      @include mq($mq-md) {
        font-size: pxToRem(7px);
      }
    }

    &.lg {
      font-size: pxToRem(15px);
      @include mq($mq-lg) {
        font-size: pxToRem(12px);
      }
      @include mq($mq-md) {
        font-size: pxToRem(10px);
      }
    }

    &.hidden {
      display: none;
    }
  }

  &-value {
    display: inline-block;
    padding: 1px 6px;
    border-radius: $border-radius-boxes;

    &.fresh {
      background-color: var(--white);
      color: var(--steel-grey);
    }

    &.bg-grey {
      background-color: var(--bg-grey);
      color: var(--dark-blue);
    }

    &.soon {
      background-color: var(--orange);
      color: var(--white);
    }

    &.expired {
      background-color: var(--grapefruit);
      color: var(--white);
    }

    &.bold {
      font-weight: 800;
    }

    &.slug-value {
      font-size: 0.85rem;
      margin-left: 16px;
    }

    &.sm {
      font-size: pxToRem(12px);
      @include mq($mq-lg) {
        font-size: pxToRem(10px);
      }
      @include mq($mq-md) {
        font-size: pxToRem(10px);
      }
    }
    &.lg {
      font-size: pxToRem(15px);
      @include mq($mq-lg) {
        font-size: pxToRem(12px);
      }
      @include mq($mq-md) {
        font-size: pxToRem(10px);
      }
    }

    &.border-radius-small {
      border-radius: 3px;
    }
  }
}
</style>
